









































import {
  defineComponent,
  PropType,
  computed,
  useContext,
  useRoute,
  ref,
  watch
} from '@nuxtjs/composition-api';
import type { MegaMenuItemTree } from '@wemade-vsf/megamenu';

export default defineComponent({
  name: 'MegaMenuItemVertical',
  props: {
    item: {
      type: Object as PropType<MegaMenuItemTree>,
      required: true
    },
    level: {
      type: Number,
      default: 0
    }
  },
  setup (props) {
    const { localePath, app: { $wm } } = useContext()
    const { item, level } = props
    const route = useRoute()
    const isOpen = ref(false)

    const itemClasses = computed(() => {
      return {
        open: isOpen.value,
        [`level-${level}`]: true,
        'has-submenu': hasSubmenu.value,
        ...item.custom_class ? { [item.custom_class]: true } : {}
      }
    })

    const hasChildren = computed(() => item.children && item.children.length > 0)
    const hasSubmenu = computed(() => hasChildren.value || (item.show_subcategories && level > 0))

    let openTimeout = null
    const enter = () => {
      if (openTimeout) {
        return
      }
      openTimeout = setTimeout(() => {
        isOpen.value = true
      }, 300)
    }
    const leave = () => {
      if (openTimeout) {
        clearTimeout(openTimeout)
      }
      openTimeout = null
      isOpen.value = false
    }

    const toggleMenu = () => {
      isOpen.value = !isOpen.value
    }

    const handlers = $wm?.theme?.menuOpener === 'click' && level === 0
      ? { click: toggleMenu }
      : { mouseover: enter, mouseleave: leave }

    const dynamicComponent = computed(() => {
      if (['category', 'product', 'page', 'internal'].includes(item.link_type) && item.link) {
        return 'nuxt-link'
      } else if (item.link) {
        return 'a'
      }
      return 'span'
    })

    const componentProps = computed(() => {
      let binds = {}
      if (dynamicComponent.value === 'nuxt-link') {
        binds['to'] = localePath(`${item.link.startsWith('/') ? '' : '/'}${item.link}`)
      } else if (dynamicComponent.value === 'a') {
        binds['href'] = item.link
      }

      if (!['category', 'page'].includes(item.link_type) && item.target && item.target !== '_self') {
        binds['target'] = item.target
        binds['rel'] = 'noopener'
      }

      return binds
    })

    watch(() => route.value,
    () => {
      leave()
    })

    return {
      dynamicComponent,
      itemClasses,
      componentProps,
      hasSubmenu,
      isOpen,
      handlers,
      enter,
      leave
    }
  }
})
